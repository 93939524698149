; (function ($, emailjs, window, document, undefined) {

  'use strict';

  // Fix le header
  var fixHeader = function () {
    var $header = $('.header');
    $(window).scroll(function () {
      if ($(window).scrollTop() > 10 && !$header.hasClass('fixed')) {
        $header.addClass('fixed');
      } else if ($(window).scrollTop() < 11 && $header.hasClass('fixed')) {
        $header.removeClass('fixed');
      }
    });
  };

  // POPUP
  function PopUp(hideOrshow) {
    if (hideOrshow == 'hide') document.getElementById('ac-wrapper').style.display = "none";
    else document.getElementById('ac-wrapper').removeAttribute('style');
  }
  window.onload = function () {
    setTimeout(function () {
      PopUp('show');
    }, 200);
  }

  var formCalltoAction = (function ($) {

    var $form = $('form#info_form');

    var showFormError = function () {
      $form.find('.form-error')
        .html('Veuillez vérifier vos réponses, certains champs sont vides ou non valides').show();
    };

    var hideFormError = function () {
      $form.find('.form-error').html('').hide();
    };

    var isEmail = function (email) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    };

    var isPhone = function (phone) {
      var regex = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
      return regex.test(phone);
    };

    // validate one field given its value and data type [email or phone]
    var validateField = function (v, dt) {
      var r = {
        'value': true,
        'message': ''
      };
      if (v === '') {
        r.value = false;
      }
      if (dt === 'email' && !isEmail(v)) {
        r.value = false;
      } else if (dt === 'phone' && !isPhone(v)) {
        r.value = false;
      }
      return r;
    };

    var validatePage = function ($page) {
      var $inputGroup = $page.find('.form-group');
      var valid = true;
      $inputGroup.each(function (i, e) {
        var $e = $(e);
        // Si checkbox ou radiobutton
        if ($e.hasClass('form-checkbox-list')) {
          if ($e.find('input:radio:checked').length === 0) {
            valid = false;
          }
        } else {
          $e.find('input:not([type=hidden]), select').each(function (index, element) {
            var $input = $(element);
            var validateResult = validateField($input.val(), $input.data('type'));
            if (!validateResult.value) {
              valid = false;
            }
          });
        }
      });
      return valid;
    };


    var navigateToNextPage = function (currentTarget) {
      var $currentPage = $(currentTarget).closest('.lightbox-content.page');

      // Validate current page avant
      if (validatePage($currentPage)) {
        hideFormError();
        var $nextPage = $currentPage.next('.lightbox-content.page');
        $currentPage.attr('data-slide', 'hideLeft');
        $nextPage.attr('data-slide', 'show');
      } else {
        showFormError();
      }
    };

    var sendEmail = function (currentTarget) {
      navigateToNextPage(currentTarget);
      var service_id = "gmail_baizana";
      var template_id = "soumission_form";
      var $pageConfirm = $form.find('.page-confirm');
      var $currentPage = $form.find("[data-slide='show']");

      var templateParams = {
        soumission_name: $('input[name=soumission_name]').val(),
        soumission_business: $('input[name=soumission_business]').val(),
        soumission_mail: $('input[name=soumission_mail]').val(),
        soumission_phone: $('input[name=soumission_phone]').val(),
        soumission_event_type: $("input[type=radio][name=soumission_event_type]:checked").val(),
        soumission_project_date: $('input[name=soumission_project_date]:checked').val(),
      };

      emailjs.send(service_id, template_id, templateParams)
        .then(function (response) {
          //console.log('SUCCESS!', response.status, response.text);

          if (response.status === 200) {
            $currentPage.attr("data-slide", 'hideLeft');
            $pageConfirm.attr('data-slide', 'show');
            $pageConfirm.find('.wait').hide(function () {
              $pageConfirm.find('.message.success').show();
              //on success, reset form and flags
              $('form#info_form')[0].reset();
            });
          } else {
            $currentPage.attr("data-slide", 'hideLeft');
            $pageConfirm.attr('data-slide', 'show');
            $pageConfirm.find('.wait').hide(function () {
              $pageConfirm.find('.message.error').show();
            });
          }
        }, function (error) {
          $currentPage.attr("data-slide", 'hideLeft');
          $pageConfirm.attr('data-slide', 'show');
          $pageConfirm.find('.wait').hide(function () {
            $pageConfirm.find('.message.error').show();
          });
        });
    };

    var datajs = function (key, value) {
      return document.querySelectorAll('[data-' + key + '=' + value + ']');
    };

    var clickHandler = function (e) {
      e.preventDefault();
      var $currentPage = $(e.currentTarget).closest('.lightbox-content.page');
      if (validatePage($currentPage)) {
        sendEmail();
      }
      else {
        showFormError();
      }
    };

    var resetForm = function () {
      $('body').removeClass('no-overflow');
      setTimeout(function () {
        $form.find('.page').attr('data-slide', 'hideRight');
        $form.find('.page1').removeAttr('data-slide');
        $form[0].reset();
      }, 300);
    };

    var preventBodyScroll = function () {
      $('body').addClass('no-overflow');
    };

    var bindEvents = function () {
      $(datajs('type', 'submit')).on('click', clickHandler);
      $form.find('button.nextPage').on('click', function (e) {
        navigateToNextPage(e.currentTarget);
      });
      $('#close-lightbox').on('click', resetForm);
      $('a[href$="#f"]').on('click', preventBodyScroll);
    };

    var init = function () {
      bindEvents();
    };

    return {
      init: init
    };

  })(jQuery);

  //formCalltoAction.init();
  fixHeader();

})(jQuery, emailjs, window, document);

$(window).scroll(function (e) {
  if (e.currentTarget.scrollY <= 200) {
    $('.scroll-top').css("opacity", "0");
  } else {
    $('.scroll-top').css("opacity", "1");
  }
});

$(function () {
  $('.slider').slick({
    autoplay: false
  });
});